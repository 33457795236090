<template>
  <custom-page class="root" ref="root">
    <div ref="root">
      <!--打印的页眉-->
      <div class="print-header">
        <div class="bg"></div>
        <div class="header">
          <img src="@/assets/image/logo_small.png" alt="">
          <div>{{ $t("custom.page6.print.title") }}</div>
        </div>

        <div>
          {{ $t("custom.page6.print.priductModel") }}：{{ figureNumber }}
        </div>

      </div>

      <group-card title="custom.page1.芯体" in-line>
      	<!--长-->
      	<group-item label="custom.page1.长">{{ calcRes.dLength }}{{userUnit.length}}</group-item>
      	<!--扁管数量-->
      	<group-item label="custom.page2.扁管数量">{{ calcRes.Ntube }}</group-item>
      	<!--高-->
      	<group-item label="custom.page1.高">{{ calcRes.dHeight }}{{userUnit.length}}</group-item>
      	<!--扁管型号-->
      	<group-item label="custom.page2.扁管型号">{{ `${dTube.w}*${dTube.h}*${dTube.n}` }}</group-item>
      	<!--排数-->
      	<group-item label="custom.page1.排数">{{ calcModel.iBank }}</group-item>
      	<!--翅片型号-->
      	<group-item label="custom.page2.翅片型号">{{ `${dFin.thick}*${dFin.w}*${dFin.h} FPI${dFin.fpi}` }}</group-item>
      	<!--流程-->
      	<group-item label="custom.page2.流程">{{ calcRes.NPass }}</group-item>
      	<group-item label="newadd.t.内容积">{{ calcRes.dVolin }}L</group-item>
      	
      
      </group-card>

      <group-card title="custom.page3.部件" in-line>
        <template v-if="customInfo.page3.type == 0">
          <!--风扇直径-->
          <group-item label="custom.page3.风扇直径">{{ customInfo.page3.fanDiameter }}mm</group-item>
          <template v-if="customInfo.page3.installationFan">
            <!--风机安装孔直径-->
            <group-item label="custom.page3.风机安装孔直径">{{ customInfo.page3.apertureDiameter }}mm</group-item>
            <!--风机数量-->
            <group-item label="custom.page3.风机数量">{{ customInfo.page3.fanNum }}</group-item>
          </template>
          <!--风罩位置-->
          <group-item label="custom.page3.风罩位置">{{ getFanPosition() }}</group-item>
          <!--拉铆螺母规格-->
          <group-item label="custom.page3.拉铆螺母规格">{{ $lang(customInfo.page3.nut, 'title') }}</group-item>
          <!--是否翻边-->
          <group-item label="custom.page3.是否翻边">{{ getIsFlanging() }}</group-item>
        </template>

        <template v-if="customInfo.page3.type == 1">
          <!--支架位置-->
          <group-item label="custom.page3.支架位置">{{ getBracketLocation() }}</group-item>
          <!--支架规格-->
          <group-item label="custom.page6.支架规格">{{ getBrackeInfo() }}</group-item>
        </template>
		
	
        <!--空一行-->
        <group-item></group-item>
        <group-item></group-item>

        <!--接管材料-->
        <group-item label="custom.page4.材质">{{ $t("custom.material." + customInfo.buttTube.material) }}</group-item>
        <!--接管形状-->
        <group-item label="custom.page4.形状">{{ getShap() }}</group-item>
        <!--进口管规格-->
        <group-item label="custom.page4.进口管">{{ getButtTubeInfo(customInfo.buttTube.in) }}</group-item>
        <!--出口管规格-->
        <group-item label="custom.page4.出口管">{{ getButtTubeInfo(customInfo.buttTube.out) }}</group-item>

        <!--空一行-->
        <group-item></group-item>
        <group-item></group-item>

        <!--涂层-->
        <group-item label="custom.page5.涂层">{{ $t("custom.page5.涂层" + customInfo.coating) }}</group-item>
        <!--是否充氮-->
        <group-item label="custom.page5.是否充氮">
          {{ $t("common.boolean." + customInfo.isNitrogenFilling.toString()) }}
        </group-item>


      </group-card>

     <group-card title="custom.page2.性能" in-line>
     	<!--换热量-->
     	<group-item label="custom.page2.换热量">{{ calcRes.dQ }}{{userUnit.heatLoad}}</group-item>
     	
     	<!--冷媒-->
     	<group-item label="custom.page0.冷媒">{{ calcModel.sRefName }}</group-item>
     	
     	<!--环境温度-->
     	<group-item label="custom.page0.环境温度">{{ calcModel.dAirInT }}{{userUnit.temperature}}</group-item>
     	
     	<!--排气温度-->
     	<group-item label="custom.page0.排气温度">{{ calcModel.dRefInT }}{{userUnit.temperature}}</group-item>
     	
     	<!--出风温度-->
     	<group-item label="custom.page2.出风温度">{{ calcRes.dTout_a }}{{userUnit.temperature}}</group-item>
     	
     	<!--排气压力-->
     	<group-item label="custom.page0.排气压力">{{ calcModel.dRefInP }}{{userUnit.pressure}}</group-item>
     	
     	<!--风速-->
     	<group-item label="custom.page0.风速">{{ calcRes.dVel_a }}{{userUnit.velocity}}</group-item>
     	<!--过冷度-->
     	<group-item label="custom.page0.过冷度" v-if="calcModel.iPatt == 0">{{ calcModel.dRefOutSC }}{{userUnit.temperature}}</group-item>
      <group-item label="custom.page0.工质流量" v-if="calcModel.iPatt == 1">{{ calcModel.dRefVF }}{{"L/min"}}</group-item>	
     	<!--风量-->
     	<group-item label="custom.page0.风量">{{ calcRes.dVF_a }}{{userUnit.volumeFlow}}</group-item>
     	<!--流量-->
     	<group-item label="custom.page2.流量">{{ calcRes.dMF_r }}{{userUnit.massFlow}}</group-item>
     	<!--风压-->
     	<group-item label="custom.page0.风压">{{ calcRes.dDP_a }}{{userUnit.airPressureDrop}}</group-item>
     	<!--冷媒降压-->
     	<group-item label="custom.page2.冷媒降压">{{ calcRes.dDP_r }}{{userUnit.refPressureDrop}}</group-item>
     	
     	<!--冷媒充注量-->
     	<!-- <group-item label="custom.page2.冷媒充注量">{{ calcRes.dCharge }}{{userUnit.charge}}</group-item> -->
     </group-card>


      <div class="action-bar no-print">
        <el-button type="" class="btn" @click="handlePrevClick">{{ $t("common.actions.pre") }}</el-button>
        <el-button type="info" class="btn" @click="handlePrint">{{ $t("common.actions.print") }}</el-button>
        <el-button type="primary" class="btn" @click="handleConfirm">{{ $t("custom.page6.设计图报价") }}</el-button>
      </div>
    </div>
  </custom-page>
</template>

<script>
import CustomPage from "@/views/Custom/components/CustomPage";
import GroupCard from "@/views/Custom/components/GroupCard";
import GroupItem from "@/views/Custom/components/GroupItem";
import mixin from "@/views/Custom/components/mixins/mixins";
import {mapGetters} from "vuex";

export default {
  name: "page6",
  components: {GroupItem, GroupCard, CustomPage},
  data() {
    return {
      figureNumber: '',
	  userUnit:{
	  	
	  }
    	 	
    }
  },
  mixins: [mixin],
  computed: {
    ...mapGetters(["customInfo", "calcRes", "calcModel", "config"]),

    dTube() {
      return this.calcModel.dTube || {};
    },
    dFin() {
      return this.calcModel.dFin || {};
    }
  },
  mounted() {
    this.generateFigureNumber();
	 this.getUserUnit();
  },
  methods: {
	  async getUserUnit() {
	    this.userUnit = await this.$store.dispatch("GetUserUnitOrCache");
	    
	  },
    //#region 零件展示
    getButtTubeInfo(info) {
		
      let model = info.otherObj
      let {thickness, diameter, flaring, flaringID} = model
      let suffix = flaring.value ? (flaringID.value + flaringID.unit) : this.$t("common.null")
      return `φ${diameter.value}*${thickness.value} ${suffix}`
    },
    getShap() {
      let b = this.customInfo.buttTube.shape;
      let flag = "custom.shap." + b
      return this.$t(flag)
    },
    getIsFlanging() {
      let b = this.customInfo.page3.isFlanging;
      let flag = "common.boolean." + b.toString()
      return this.$t(flag)
    },
    getFanPosition() {
      let position = this.customInfo.page3.fanPosition;
      let flag = "custom.hoodPosition." + position
      return this.$t(flag)
    },
    getBracketLocation() {
      let n = this.customInfo.page3.bracketLocation
      let flag = "custom.bracketPosition." + n
      return this.$t(flag)
    },
    getBrackeInfo() {
		if( !this.customInfo.page3.bracket){
			
			return
		}
      let model = this.customInfo.page3.bracket.otherObj;
      return `${model.length.value}*${model.width.value}*${model.height.value}`;
    },
    //#endregion

    async generateFigureNumber() {
      this.figureNumber = await this.$store.dispatch("GetFigureNumber", this.module);
    },

    handlePrint() {
      this.$print(this.$refs.root)
    },
    handleConfirm() {
		  console.log(this.customInfo,666)
      this.$store.commit("SET_PARAMS", this.customInfo)
      this.$store.commit("SET_CUSTOM_INFO", {confirm: true})

      let params = {num: 1, remark: "", figureNumber: "CIMC-21236"}
      this.$req.post("/client/order/reduceDesignPoints", params).then(async res => {
          this.$nextTick(_ => this.$router.push({path: '/custom/confirm', query: {type: this.type}}));
        });

      // this.$nextTick(_ => this.$router.push({path: '/custom/confirm', query: {type: this.type}}))
    },
  },
}
</script>

<style scoped lang='scss'>

.action-bar {
  text-align: center;

  .btn {
    width: 300px;
  }
}

.print-header {
  display: none;
}

.root::v-deep {

  @media print {

    .page-wrapper {
      margin: 0 !important;
      margin-left: 35px !important;
    }

    .group-card {

      .content {
        padding: 15px 45px;
      }

      .title {
        border-bottom: 2px solid #eee;
      }

    }

    .print-header {
      position: relative;
      display: block;

      .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        border: 25px solid #4d5c7d;

      }

      .header {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #ffffff;
        font-weight: bold;
        font-size: 18px;

        img {
          position: absolute;
          left: 10px;
          top: 15px;
        }
      }
    }
  }
}
</style>